<!--
 * @Author: 张博洋
 * @Date: 2021-08-02 13:51:49
 * @LastEditTime: 2022-03-01 09:52:20
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/components/patient/patientListPanel.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <van-popup v-model="show"
             round
             position="bottom"
             close-on-popstate
             :overlay="true"
             get-container="body"
             @close="closePanel"
             class="patient-list-panel">
    <div class="title">选择就诊人</div>
    <div class="con">
      <div class="patient-item"
           v-for="(item,index) in list"
           :key="index"
           @click="select(item)">
        <div class="info">
          <span class="name mr12">{{item.patientName}}</span>
          <div>
            <span class="sex ">{{item.sex === 1 ? '男' : '女'}}</span>
            <span class="age ml12">{{item.age}}岁</span>
            <span class="def ml12"
                  v-if="item.setDefault === 1">默认就诊人</span>
          </div>

        </div>
        <div class="tel mt12">手机号：{{item.phone}}</div>
        <div class="id mt4">证件号：{{item.idCardNum}}</div>

      </div>
    </div>

    <van-button style="margin-top:24px"
                color="rgba(26, 179, 112, 1)"
                plain
                round
                
                @click="toAddPatient">添加就诊人</van-button>
  </van-popup>
</template>
<script>
export default {
  name: 'PatientListPanel',
  props: {
    list: {
      type: Array,
    },
    patientIsShow: {
      type: Boolean, 
    },
  },
  data() {
    return {
      show:true,
    }
  },
  watch: {
    'patientIsShow': {
      handler(val) {
        console.log('======',val)
        this.show = val
      },
      immediate: true,
    },
  },

  created() {},
  activated() {
  },
  methods: {
    select(item) {
      this.show = false
      this.$emit('closePatient', item)
    },
    closePanel() {
      this.show = false
      this.$emit('closePanel')
    },
    toAddPatient() {
      this.$router.push({
        name: 'patient.add',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.patient-list-panel {
  display: flex;
  flex-direction: column;
  padding: 12px 30px;
  max-height: 500px;
  padding-bottom: calc(12px +  constant(safe-area-inset-bottom));
  padding-bottom: calc(12px +  env(safe-area-inset-bottom));
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  .con {
    flex: 1;
    max-height: 340px;
    overflow: auto;
    margin-top: 10px;
    .patient-item {
      padding: 14px 0;
      border-bottom: 1px solid #f5f5f5;
      .info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .name {
          font-size: 15px;
          font-weight: 500;
          color: #333333;
        }
        .sex,
        .age {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
        .def {
          font-size: 12px;
          font-weight: 400;
          color: #1ab370;
          text-align: center;
          border-radius: 2px;
          border: 1px solid #1ab370;
          padding: 0px 4px;
        }
      }
      .tel,
      .id {
        font-size: 14px;
        font-weight: 400;
        color: #999;
      }
    }
  }
}
</style>
