<!--
 * @Author: 张博洋
 * @Date: 2021-08-06 23:49:00
 * @LastEditTime: 2022-03-10 11:41:32
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/views/order/views/list.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->

<template>
  <div class="pr full over-y wrap">
    <van-pull-refresh v-model="refreshing"
                      @refresh="onRefresh">
      <van-list v-model="loading"
                :finished="finished"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                @load="getData">
        <div class="pd8"
             v-if="list.length">
          <!-- {{type}} -->
          <list-m :d="item"
                  :query="query"
                  v-for="(item,index) in list"
                  :key="index"
                  @refreshList="refreshList"></list-m>
        </div>
        <div v-if="!list.length && empty"
             class="empty full-h">
          <img width="120"
               src="../../../static/img/order-empty.png">
          <p>暂无订单</p>
        </div>
      </van-list>

    </van-pull-refresh>

  </div>
</template>

<script>
import listM from '../components/order'
export default {
  components: {
    listM,
  },
  props: {
    query: {
      type: Object,
    },
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      empty: false,
      error: false,
    }
  },
  created() {
    this.onRefresh()
  },
  watch:{
    'query.patientId':{
        handler(val) {
        console.log(this.query)
        this.onRefresh()
        }
    }
  },
  mounted(){
  },
  computed: {},
  methods: {
    getData() {
      this.empty = false
      this.$emit('ajaxThen')
      this.loading = true
      setTimeout(() => {
        this.$axios({
          type: 'post',
          url: 'order/getServiceOrder',
          data: this.query,
          elseData: {
            ajaxLoading: true,
            errorTip: false,
          },
        })
          .then((res) => {
            console.log(res)
            res.d.data || (res.d.data = [])
            this.list = [...this.list, ...res.d.data]

            this.loading = false
            // 数据全部加载完成
            if (this.list.length >= res.d.total) {
              this.finished = true
            }
            if (!res.d.data.length) {
              this.empty = true
            }
          })
          .catch((e) => {
            this.error = true
          })
      }, 100)
    },
    onRefresh() {
      // 清空列表数据
      this.empty = false
      this.$emit('refresh')
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      setTimeout(() => {
        this.$axios({
          type: 'post',
          url: 'order/getServiceOrder',
          data: this.query,
          elseData: {
            ajaxLoading: true,
            errorTip: false,
          },
        })
          .then((res) => {
            console.log(res)
            res.d.data || (res.d.data = [])
            this.list = [...res.d.data]
            this.loading = false
            this.refreshing = false
            // 数据全部加载完成
            if (this.list.length >= res.d.total) {
              this.finished = true
            }
            if (!res.d.data.length) {
              this.empty = true
            }
          })
          .catch(() => {
            this.error = true
            this.loading = false
          })
      }, 100)
    },
    refreshList() {
      this.onRefresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  .van-pull-refresh {
    min-height: 100%;
    max-height: 100%;
    overflow: auto;
  }
  /deep/.van-pull-refresh__track {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .van-list {
      height: 100%;
    }
  }

  .empty {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 14px;
    }
    img {
      margin-top: calc(50% - 80px);
    }
    color: #999;
  }
}
</style>
