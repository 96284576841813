<!--
 * @Author: 张博洋
 * @Date: 2021-08-09 13:48:33
 * @LastEditTime: 2022-02-25 16:07:49
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/order/components/order.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="item bg-white radius8">
    <div class="head">
      <div class="left">
        <span class="name">{{d.doctorName}}</span>
        <span class="title ml12">{{d.technicalName}}</span>
        <service class="ml12"
                 :orderType="d.orderType"></service>
      </div>
      <div class="status"
           :style="{'color':countColor}">{{countText}}</div>
    </div>
    <div class="content">
      <!-- <p>{{d.diseaseDesc}}</p> -->
      <v-clamp v-if="d.diseaseDesc.length"
               autoresize
               location="end"
               :max-lines="2"
               :expanded="expanded"
               @clampchange="clampchange">
        {{ d.diseaseDesc }}
        <span class="slotBtn"
              v-if="btnShow"
              slot="after"
              @click="toggle">
          {{ expanded?  '[收起]' :'[更多]' }}
        </span>
      </v-clamp>
      <!-- <div class="images mt10">
        <van-image width="70"
                   height="70"
                   fit="contain"
                   :src="require('@/static/img/image.png')"
                   alt="" />

      </div> -->

      <!-- <div style="display:flex; flex-wrap: wrap;"
           ref="images">
        <van-image style="margin:10px 8px 0px 0"
                   v-for="(img, imgIndex) in formatImage(d.imageUrls)"
                   :key="imgIndex"
                   :src="img"
                   :width="imgWidth"
                   :height="imgWidth"
                   fit="contain" />
      </div> -->

      <div class="images mt10"
           ref="images">
        <div class="img"
             v-for="(img, imgIndex) in formatImage(d.imageUrls)"
             :key="imgIndex">
          <img :src="img"
               :width="imgWidth"
               :height="imgWidth"
               alt="" />
        </div>

      </div>
    </div>
    <div class="bottom mt4">
      <div class="time">{{$.replyTime(d.createTime)}}</div>
      <div class="btns">
        <van-button round
                    plain
                    color="#1AB370"
                    @click="checkDetail(d.id,d.roomId)">查看详情</van-button>
        <van-button class="ml12"
                    round
                    v-if="(d.state === 4 || d.state === 7 ) && query.state === 4 && d.evaluate === 0"
                    color="#1AB370"
                    @click="evaluate">去评价</van-button>
        <van-button class="ml12"
                    round
                    color="#1AB370"
                    v-if="d.state === 1"
                    :loading='btnLoading'
                    @click="pay(d.id)">立即支付</van-button>
        <van-button class="ml12"
                    round
                    color="#1AB370"
                    v-if="d.state === 3"
                    @click="toIm(d.state,d.doctorAccountId,d.roomId,d.id)">联系医生</van-button>
      </div>
    </div>

    <evaluate-panel :evaluateShow="evaluateShow"
                    :orderId="d.id"
                    @refresh="refresh"
                    :evaluateType="evaluateType"
                    @close="handleClose"></evaluate-panel>
  </div>
</template>

<script>
import service from './service.vue'
import rate from '@/mixin/Rate.js'
import button from '@/mixin/Button.js'
import VClamp from 'vue-clamp'
import evaluatePanel from './evaluatePanel.vue'

export default {
  props: {
    d: {
      type: Object,
      default() {
        return {}
      },
    },
    query: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      maxWidth: 327,
      imgWidth: '',
      status: 0,
      expanded: false, //收否展开
      c: false,
      btnShow: false,
      evaluateShow: false,
      evaluateType: '',
    }
  },
  mixins: [button, rate],
  components: {
    service,
    VClamp,
    evaluatePanel,
  },
  created() {},
  mounted() {
    console.log(this.$refs.images.clientWidth)
    this.maxWidth = this.$refs.images.clientWidth
    this.imgWidth = Math.floor((this.maxWidth - 31) / 4)
  },
  computed: {
    countColor() {
      if (this.query.state === 1) {
        if (this.d.state === 1) {
          return '#FF8200'
        }
      } else if (this.query.state === 2) {
        if (this.d.state === 2) {
          return '#1890FF'
        } else if (this.d.state === 3) {
          return '#1AB370'
        }
      } else if (this.query.state === 3) {
        if (this.d.state === 4) {
          return '#1AB370'
        } else if (this.d.state === 5) {
          return '#999999'
        } else if (this.d.state === 7) {
          return '#1AB370'
        }
      } else if (this.query.state === 4) {
        if (this.d.state === 7 || this.d.state === 4) {
          if (this.d.evaluate === 0) {
            return '#FF8200'
          } else if (this.d.evaluate === 1) {
            return '#1890FF'
          }
        }
      }
    },
    countText() {
      if (this.query.state === 1) {
        if (this.d.state === 1) {
          return '待支付'
        }
      } else if (this.query.state === 2) {
        if (this.d.state === 2) {
          return '新建'
        } else if (this.d.state === 3) {
          return '服务中'
        }
      } else if (this.query.state === 3) {
        if (this.d.state === 4) {
          return '已完成'
        } else if (this.d.state === 5) {
          return '已取消'
        } else if (this.d.state === 7) {
          return '退款成功'
        }
      } else if (this.query.state === 4) {
        if (this.d.state === 7 || this.d.state === 4) {
          if (this.d.evaluate === 0) {
            return '待评价'
          } else if (this.d.evaluate === 1) {
            return '已评价'
          }
        }
      }
    },
  },
  methods: {
    formatImage(imageUrls) {
      if (imageUrls) {
        return imageUrls.split(',')
      }
      return []
    },
    checkDetail(id, roomId) {
      // alert(id)
      this.$router.push({
        name: 'order.detail',
        query: {
          orderId: id,
          roomId: roomId,
        },
      })
    },
    toIm(state, doctorAccountId, roomId, id) {
      this.$router.push({
        name: 'im.chat',
        query: {
          state: state,
          doctorAccountId: doctorAccountId,
          roomId: roomId,
          orderid: id,
          orderType: 2,
        },
      })
    },
    toggle() {
      this.c = true
      this.expanded = !this.expanded
    },
    clampchange(expanded) {
      if (!expanded && !this.c) {
        this.btnShow = false
      } else {
        this.btnShow = true
      }
    },
    evaluate() {
      this.evaluateType = 'add'
      this.evaluateShow = true
    },
    refresh(payload) {
      this.evaluateShow = false
      this.$emit('refreshList')
    },
    handleClose() {
      this.evaluateShow = false
    },
    pay(id) {
      wx.miniProgram.navigateTo({
        url: `/pages/pay/pay?id=${id}`,
      })
      // this.$axios({
      //   type: 'post',
      //   url: 'pay/prepayApply',
      //   data: {
      //     id: id
      //   },
      //   elseData: {
      //     btnLoading: true
      //   }
      // }).then(res => {
      //   //    'timeStamp': payinfo.timeStamp,
      //   // 'nonceStr': payinfo.nonceStr,
      //   // 'package': payinfo.package,
      //   // 'signType': payinfo.signType,
      //   // 'paySign': payinfo.paySign,
      //   console.log(res)
      //   const timeStamp = res.d.timeStamp;
      //   const nonceStr = res.d.nonceStr;
      //   const prepayId = res.d.purePrepayId;
      //   const signType = res.d.signType;
      //   const paySign = res.d.paySign;
      //   console.log(prepayId)
      //   const payInfo = JSON.stringify({
      //     timeStamp: timeStamp,
      //     nonceStr: nonceStr,
      //     prepayId: prepayId,
      //     signType: signType,
      //     paySign: paySign,
      //   })
      //   const params = `?payInfo=${payInfo}`;
      //   const path = "/pages/pay/pay" + params;
      //   wx.miniProgram.navigateTo({ url: '/pages/pay/pay'});
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.item {
  padding: 4px 16px 8px;
  margin-bottom: 8px;
  .head {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
  .content {
    width: 100%;
    background: #f5f5f5;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    padding: 10px 10px 0 10px;
    /deep/.slotBtn {
      color: color(primary);
    }
    .images {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .img {
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .img:nth-child(4n) {
        margin-right: 0px;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
    .time {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
    .btns {
      display: flex;
      .van-button {
        width: 80px;
        height: 32px;
        font-size: 14px;
        padding: 0;
      }
    }
  }
}
// .item:last-child {
//   margin-bottom: 28px;
// }
</style>