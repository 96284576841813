<!--
 * @Author: 张博洋
 * @Date: 2021-08-09 13:57:06
 * @LastEditTime: 2021-11-19 17:57:23
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/order/components/service.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <span class="service"
        :class="orderType === 1 ? 's1' : 's2'">{{orderType === 1 ? '在线咨询' : '在线问诊'}}</span>
</template>

<script>
export default {
  props: {
    orderType: Number
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.service {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 6px;
}
.s1 {
  width: 60px;
  height: 20px;
  background: rgba(255, 130, 0, 0.1);
  border-radius: 4px;
  color: #ff8200;
}
.s2 {
  width: 60px;
  height: 20px;
  background: rgba(26, 179, 112, 0.1);
  border-radius: 4px;
  color: #1ab370;
}
</style>
